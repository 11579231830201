<template>
  <div>
    <FormAlert @submit-requested="submitRequested"></FormAlert>
  </div>
</template>
 
<script>
import { mapActions } from 'vuex'
import FormAlert from '../forms/FormAlert.vue'

  export default {
  components: { FormAlert },
  methods: {
    ...mapActions('alerts', ['createAlert']),
    submitRequested (alert) {
      this.createAlert(alert);
    },
  }

  }
</script> 
 
<style scoped>
thead > tr > th
{
  text-align: unset !important;
}
</style> 